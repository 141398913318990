<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户id，外键{zb_user.id}" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id，外键{zb_user.id}" />
      </a-form-model-item>
      <a-form-model-item label="被关注或喜欢用户id" prop="followUserId" >
        <a-input v-model="form.followUserId" placeholder="请输入被关注或喜欢用户id" />
      </a-form-model-item>
      <a-form-model-item label="类型0关注1喜欢" prop="type" >
      </a-form-model-item>
      <a-form-model-item label="是否互关 0关注 1互关" prop="isToo" >
        <a-input v-model="form.isToo" placeholder="请输入是否互关 0关注 1互关" />
      </a-form-model-item>
      <a-form-model-item label="处理时间" prop="handleTime" >
        <a-date-picker style="width: 100%" v-model="form.handleTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getFollow, addFollow, updateFollow } from '@/api/user/follow'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        followUserId: null,

        type: null,

        isToo: null,

        handleTime: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户id，外键{zb_user.id}不能为空', trigger: 'blur' }
        ],
        followUserId: [
          { required: true, message: '被关注或喜欢用户id不能为空', trigger: 'blur' }
        ],
        isToo: [
          { required: true, message: '是否互关 0关注 1互关不能为空', trigger: 'blur' }
        ],
        handleTime: [
          { required: true, message: '处理时间不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        followUserId: null,
        type: null,
        isToo: null,
        handleTime: null,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getFollow({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateFollow(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addFollow(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
