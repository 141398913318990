import request from '@/utils/request'


// 查询用户关注、喜欢列表
export function listFollow(query) {
  return request({
    url: '/user/user-follow/list',
    method: 'get',
    params: query
  })
}

// 查询用户关注、喜欢分页
export function pageFollow(query) {
  return request({
    url: '/user/user-follow/page',
    method: 'get',
    params: query
  })
}

// 查询用户关注、喜欢详细
export function getFollow(data) {
  return request({
    url: '/user/user-follow/detail',
    method: 'get',
    params: data
  })
}

// 新增用户关注、喜欢
export function addFollow(data) {
  return request({
    url: '/user/user-follow/add',
    method: 'post',
    data: data
  })
}

// 修改用户关注、喜欢
export function updateFollow(data) {
  return request({
    url: '/user/user-follow/edit',
    method: 'post',
    data: data
  })
}

// 删除用户关注、喜欢
export function delFollow(data) {
  return request({
    url: '/user/user-follow/delete',
    method: 'post',
    data: data
  })
}
